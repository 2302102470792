<template>
<v-app>
    <v-dialog v-model="showDialog" max-width="1000px">
        <v-card v-if="showDialog">
            <v-card-title>
                <span>Pedido #{{orden_id}}</span>
            </v-card-title>
            <v-card-text>
                <div class="row">
                    <div class="col">
                        <v-select
                            v-model="current_state"
                            :items="orderStatesList"
                            :rules="[v => !!v || 'Debes elegir un estado']"
                            label="Estado del pedido"
                            required
                            @change="handleChangeOrderStateSelect"
                        ></v-select>
                    </div>
                </div>
                <div class="row" v-if="orderSubStatesList.length">
                    <div class="col">
                        <v-select
                            v-model="current_substate"
                            :items="orderSubStatesList"
                            :rules="[v => !!v || 'Debes elegir un subestado']"
                            label="Subestado del pedido"
                            required
                        ></v-select>
                    </div>
                </div>
                <div class="row" v-if="orderValdidationState">
                    <div class="col">
                        <v-checkbox v-model="validStateChange" label="Validar en sistema Logístico?" > Validar en sistema Logístico? </v-checkbox>
                    </div>
                </div>
                
            </v-card-text>
            <v-card-actions>
                <v-btn @click="showDialog = false" dark color="pink" >
                    Cancelar
                </v-btn>
                <v-btn color="success" class="btn-starkons-primary" @click="sendForm" :disabled="disableSendFormBtn" >
                    Guardar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-app>
</template>

<script>
export default {
    data(){
        return {
            showDialog: false,
            orden_id: undefined,
            store_id: undefined,
            current_state: undefined,
            current_substate: undefined,
            orderStatesList: [],
            orderSubStatesList: [],
            disableSendFormBtn: false,
        }
    },
    created(){
        
        this.$parent.$on('openDialogCambiarEstadoOrden', (datos) => {
            this.orden_id = datos.order_id;
            this.store_id = datos.store_id;
            this.current_state = datos.current_state;
            this.current_substate = datos.current_substate_name ? datos.current_substate_name : undefined;
            this.orderValdidationState = undefined
            this.validStateChange = 1;
            console.log('datos', datos);
            //this.current_substate = datos.
            this.fetchData();
        });
    },
    methods: {
        handleChangeOrderStateSelect(){
            const orderStateFromList = this.orderStatesList.find(e => e.value == this.current_state);
            if(orderStateFromList.substates.length){
                this.orderSubStatesList = orderStateFromList.substates;
            }else{
                this.orderSubStatesList = [];
            }
            if(!this.orderSubStatesList.find(e => e.value == this.current_substate)){
                this.current_substate = undefined;
            }
            
            console.log(orderStateFromList.additional_data.state_to_validate);
            if(orderStateFromList.additional_data.state_to_validate && orderStateFromList.additional_data.state_to_validate == 1 ){
                this.orderValdidationState = true;
            }
            else{
                this.orderValdidationState = false;
            }
            console.log('orderValdidationState');
            console.log(this.orderValdidationState);
            
        },
        sendForm(){
            var vm = this;
            vm.disableSendFormBtn = true;
            this.axios({
                url: 'orders/'+this.orden_id+'/state',
                method: 'POST',
                data: {
                    state: this.current_state,
                    substate: this.orderSubStatesList.length ? this.current_substate : null,
                    validatestatelogistic: this.orderValdidationState ? this.validStateChange : 1
                }
            }).then( () => {
                vm.showDialog = false;
                vm.$emit('success', "Estado modificado correctamente");
                vm.disableSendFormBtn = false;
            }).catch( () => {
                //console.log(error);
                vm.disableSendFormBtn = false;
            });
        },
        fetchData(){
            var vm = this;
            this.axios({
                url: 'orders/states/data_select_input?store='+this.store_id+'&order_id='+this.orden_id,
            }).then( response => {
                vm.orderStatesList = response.data;
                vm.handleChangeOrderStateSelect(); 
                vm.showDialog = true; 
                console.log('orderStatesList', vm.orderStatesList);
            }).catch( (error) => {
                console.log(error);
            });
        }
    }
}
</script>